import { DesignedGradientStyled } from 'screens/QuatroHome/styles';
import { ButtonLink } from 'components/Quatro/Button';

const DesignedSection = () => {
  const markClass =
    'typography-informative-display-primary-mobile bg-transparent text-color-text-lightmode-invert dark:text-color-text-darkmode-invert';

  return (
    <section className="relative mt-28">
      <DesignedGradientStyled />
      <img
        src="/images/quatro/home/designedbg.webp"
        alt="designed-bg"
        className="absolute inset-0 z-[2] h-full w-full object-cover mix-blend-multiply"
      />
      <div className="quatro-container relative z-[3] mx-auto flex flex-col gap-6 py-20">
        <span className="typography-product-heading-compact-desktop text-quatro-blue-text">
          Designed for immediate improvement
        </span>
        <p className="typography-informative-display-secondary-mobile max-w-[50rem] text-color-text-lightmode-invert dark:text-color-text-darkmode-invert">
          <mark className={markClass}>Quatro Pickleball</mark>
           is designed to be an experience that creates "Ah-ha moments" through a system crafted
          after observing thousands of hours of teaching on the 
          <mark className={markClass}>pickleball</mark> court.
        </p>
        <ButtonLink variant="secondary-invert" href="/quatro/clinics" size="lg">
          Upcoming Clinics
        </ButtonLink>
      </div>
    </section>
  );
};

export default DesignedSection;
