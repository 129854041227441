import React from 'react';
import { EventActiveTabEnum } from 'screens/QuatroHome/enums';
import classNames from 'styles/utils/classNames';

const Backgrounds: React.FC<{ activeTab: EventActiveTabEnum }> = ({ activeTab }) => {
  return (
    <>
      <img
        src={'/images/quatro/home/event.webp'}
        loading="lazy"
        alt="event-image"
        className={classNames(
          'opacity-1 absolute inset-0 z-[1] h-full w-full object-cover transition-opacity ease-in',
          activeTab === EventActiveTabEnum.Getaways && 'opacity-0',
        )}
      />

      <img
        src={'/images/quatro/home/event-2.jpg'}
        loading="lazy"
        alt="event-image-1"
        className={classNames(
          'opacity-1 absolute inset-0 z-[1] h-full w-full object-cover transition-opacity ease-in',
          activeTab === EventActiveTabEnum.Clinic && 'opacity-0',
        )}
      />

      <div className="absolute inset-0 z-[2] bg-[#00000090]" />
    </>
  );
};

export default Backgrounds;
