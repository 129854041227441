import { useState } from 'react';
import { QUATRO_CLINICS, QUATRO_GETAWAYS } from 'constants/pages';
import { GETAWAYS } from 'screens/QuatroGetaways/constants';
import { EventActiveTabEnum } from 'screens/QuatroHome/enums';
import { UpcomingEventsSectionProps } from 'screens/QuatroHome/props';
import { ButtonLink } from 'components/Quatro/Button';
import { GetawayCard } from 'components/Quatro/Cards';
import ClinicCard from 'components/Quatro/Cards/ClinicCard';
import Switcher from 'components/Quatro/Switcher';
import Backgrounds from './Backgrounds';

const UpcomingEventsSection: React.FC<UpcomingEventsSectionProps> = ({
  quatroUpcomingClinicLessonsData,
  retreatLessonsData,
}) => {
  const [activeTab, setActiveTab] = useState<EventActiveTabEnum>(EventActiveTabEnum.Clinic);

  return (
    <section id="upcoming-events" className="relative mt-28">
      <Backgrounds activeTab={activeTab} />
      <div className="container relative z-[3] mx-auto flex flex-col items-center justify-center px-4 py-20 sm:px-0">
        <h2 className="typography-informative-heading text-center  text-color-text-lightmode-invert">
          Upcoming Q4PB Events
        </h2>
        <Switcher
          className="mt-8 min-w-[22.5rem]"
          size="sm"
          items={[
            {
              title: 'Clinic',
              value: 'clinic',
            },
            {
              title: 'Getaways',
              value: 'getaways',
            },
          ]}
          onSwitchChange={(val) => setActiveTab(val as EventActiveTabEnum)}
        />
        <p className="typography-informative-subheading-medium mt-4 text-center text-color-text-lightmode-invert">
          Step Onto The Court And Dive Into An Immersive Pickleball Experience With Our 2 or 3-day
          Clinics
        </p>

        <div className="mt-10 grid w-full grid-cols-3 gap-8 max-lg:grid-cols-2 max-sm:grid-cols-1">
          {activeTab === EventActiveTabEnum.Clinic
            ? quatroUpcomingClinicLessonsData?.lessons.map((lesson) => (
                <ClinicCard lesson={lesson} key={lesson.id} />
              ))
            : retreatLessonsData?.lessons.map((lesson) => {
                const getawayInfo = GETAWAYS.find((getaway) => getaway.title === lesson.title);
                return (
                  <GetawayCard
                    {...lesson}
                    url={getawayInfo?.url || '#'}
                    imageUrl={getawayInfo?.imageUrl || lesson.coverImageProviderUrl}
                    key={lesson.id}
                  />
                );
              })}
        </div>
        {activeTab === EventActiveTabEnum.Clinic && (
          <ButtonLink
            size="lg"
            variant="secondary-invert"
            href={`${QUATRO_CLINICS}#clinics-section`}
            className="mt-12 w-fit"
          >
            View all clinics
          </ButtonLink>
        )}

        {activeTab === EventActiveTabEnum.Getaways && (
          <ButtonLink
            size="lg"
            variant="secondary-invert"
            href={`${QUATRO_GETAWAYS}#getaways-section`}
            className="mt-12 w-fit"
          >
            View all getaways
          </ButtonLink>
        )}
      </div>
    </section>
  );
};

export default UpcomingEventsSection;
