import { partnerLogosSrc } from './data';

const PartnersSection = () => {
  return (
    <section className="mt-28 bg-color-bg-lightmode-secondary dark:bg-color-bg-darkmode-secondary">
      <div className="container mx-auto px-4 py-20 sm:px-0">
        <h4 className="typography-product-heading-compact-desktop text-center text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          Our Trusted Partners
        </h4>
        <div className="mt-10 flex items-center gap-10 max-sm:flex-wrap max-sm:justify-center">
          {partnerLogosSrc.map((logo, index) => (
            <div key={index}>
              <img
                src={`/images/quatro/home/${logo}.png`}
                alt={logo}
                className="w-auto max-sm:h-10"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
