import { ButtonLink } from 'components/Quatro/Button';

const LearnMoreSection = () => {
  return (
    <section className="mt-28">
      <div className="quatro-container mx-auto">
        <h5 className="typography-product-heading-compact-desktop text-quatro-blue-text">
          A Winning Philosophy
        </h5>
        <div className="mt-8 flex justify-between gap-20 max-sm:flex-col max-sm:gap-10">
          <div className="flex flex-col gap-8 ">
            <h1 className="typography-informative-heading-desktop max-sm:typography-informative-heading-mobile">
              Quatro Pickleball:
              <br />
              The Power of 4
            </h1>
            <p className="max-sm:typography-informative-subheading-compact text-color-text-lightmode-secondary sm:max-w-[17rem]">
              Welcome to Quatro Pickleball, where the game is not just a sport; it's a winning
              philosophy.
            </p>
          </div>
          <div className="flex flex-col gap-8">
            <p className="max-sm:typography-product-body  typography-informative-subheading-compact text-color-text-lightmode-secondary sm:max-w-[25rem] ">
              The Q4PB philosophy revolves around breaking down the intricacies of pickleball into
              the easiest and most comprehensible elements. We've observed thousands of hours on the
              pickleball court, and through this experience, we've identified a key element: the
              number 4. <br />
              <br />
              Join us on this exhilarating journey through Quatro Pickleball. Whether you're a
              novice or a seasoned player, our philosophy is here to transform the way you
              experience and excel in the game we all love.
            </p>
            <ButtonLink size="lg" href="/quatro/about" variant="primary" className="w-fit">
              Learn More
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreSection;
