import React, { useState } from 'react';
import { SwitcherProps } from './types';

const Switcher: React.FC<SwitcherProps> = ({
  items,
  size = 'md',
  onSwitchChange,
  className = '',
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSwitch = (index: number) => {
    setActiveIndex(index);
    onSwitchChange(items[index].value);
  };

  const sizeStyles = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3 text-base',
    lg: 'px-8 py-4 text-lg',
  };

  return (
    <div
      className={`inline-flex items-center rounded-lg border border-gray-300 bg-color-bg-lightmode-primary p-[2px] shadow-sm dark:bg-color-bg-darkmode-primary ${className}`}
    >
      {items.map((item, index) => (
        <button
          key={item.value}
          className={`flex-1 rounded-lg transition-colors duration-200 ease-in-out focus:outline-none ${
            activeIndex === index
              ? 'bg-brand-fire-450 text-white'
              : 'bg-transparent text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
          } ${sizeStyles[size]}`}
          onClick={() => handleSwitch(index)}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
};

export default Switcher;
