import { format } from 'date-fns';
import Image from 'next/image';
import { ButtonLink } from 'components/Quatro/Button';
import { ButtonLinkProps } from '../Button/types';

interface ExtendedButtonLinkProps extends ButtonLinkProps {
  target?: string;
  rel?: string;
  block?: boolean;
  variant: 'secondary';
  className: string;
  href: string;
}

interface GetawayCardProps {
  title: string;
  url: string;
  imageUrl: string;
  description: string;
  startDate?: string;
  endDate?: string;
  contactEmail?: string;
}

const GetawayCard: React.FC<GetawayCardProps> = ({
  title,
  url,
  imageUrl,
  description,
  startDate,
  endDate,
  contactEmail,
}) => {
  const hasValidDates = startDate && endDate;

  const ExtendedButtonLink = ButtonLink as React.ComponentType<ExtendedButtonLinkProps>;

  const formatDateRange = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return `${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd')}, ${format(
      endDate,
      'yyyy',
    )}`;
  };

  const handleMailtoClick = () => {
    const mailtoLink = `mailto:${contactEmail}?subject=Interest in ${title}&body=Hello,%0D%0A%0D%0AI'm interested in learning more about the upcoming ${title} getaway. Could you please send me information when dates are available?%0D%0A%0D%0AThanks!`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <div className="flex flex-col border border-color-border-card-lightmode bg-color-bg-lightmode-primary shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)] dark:border-color-border-card-darkmode dark:bg-color-bg-darkmode-primary">
      <div className="relative h-48 border bg-white">
        <Image src={imageUrl} fill alt={title} objectFit="cover" />
      </div>
      <div className="flex flex-1 flex-col bg-color-bg-lightmode-primary px-6 pb-6 pt-3 dark:bg-color-bg-darkmode-primary">
        {hasValidDates ? (
          <span className="typography-product-element-label mt-3 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {formatDateRange(startDate!, endDate!)}
          </span>
        ) : (
          <span className="typography-product-element-label mt-3 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            Dates Coming Soon
          </span>
        )}
        <h3 className="typography-product-heading-desktop mt-3">{title}</h3>
        <p className="typography-product-text-card-desktop mb-6 mt-2 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          {description}
        </p>
        {hasValidDates ? (
          <ExtendedButtonLink
            href={url}
            variant="secondary"
            block
            className="mt-auto"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Details
          </ExtendedButtonLink>
        ) : (
          <button
            onClick={handleMailtoClick}
            className="border-color-border-button-lightmode dark:border-color-border-button-darkmode mt-auto block w-full rounded-md border bg-color-bg-lightmode-primary px-4 py-2 text-center text-sm font-medium text-color-text-lightmode-primary hover:bg-color-bg-lightmode-secondary dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary dark:hover:bg-color-bg-darkmode-secondary"
          >
            Coming Soon
          </button>
        )}
      </div>
    </div>
  );
};

export default GetawayCard;
