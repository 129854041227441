import { useCallback } from 'react';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import Star from 'svg/Star';
import { ReviewProp } from 'screens/QuatroHome/props';
import classNames from 'styles/utils/classNames';

const ReviewCard: React.FC<ReviewProp> = ({ name, image, comment, rating }) => {
  const renderStars = useCallback((rating: number) => {
    return Array.from({ length: 5 }, (_, index) => (
      <Star
        key={index}
        className={classNames('h-4 w-4', index < rating ? 'text-black' : 'text-gray-300')} // Filled or empty based on rating
        viewBox="0 0 19 18"
      />
    ));
  }, []);

  return (
    <div className="flex flex-col bg-color-bg-lightmode-primary p-4 shadow-lightmode-primary   dark:bg-color-bg-darkmode-primary">
      <div className="relative h-16 w-16 overflow-hidden rounded-full">
        <img
          src={image ? `/images/quatro/home/${image}` : EMPTY_AVATAR_SRC}
          className="h-full w-full object-cover"
          alt="empty-avatar"
          loading="lazy"
        />
      </div>
      <span className="typography-product-body-highlight mt-3">{name}</span>
      <div className="mt-2 flex items-center">
        {renderStars(rating)}
        <span className="typography-product-body-highlight ml-2 mt-[1px]">{rating.toFixed(1)}</span>
      </div>

      {/* currently not available */}
      {/* <span className="typography-product-caption mt-1 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          June 26, 2024
        </span> */}
      <p className="typography-product-body mt-2 whitespace-pre-line break-words text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {comment}
      </p>
    </div>
  );
};

export default ReviewCard;
