export const reviews = [
  {
    name: 'Axel Estrada',
    rating: 5,
    comment:
      "Quatro has transformed my game! Through Matt's guidance and advice, I've not only improved my technique but also developed a deeper understanding of strategy and court positioning. I can confidently say that Quatro Pickleball has been instrumental in elevating my game to new heights.",
    image: 'axel.jpeg',
  },
  {
    name: 'Angela Bates',
    rating: 5,
    comment:
      'My game has been elevated greatly under Matt’s guidance. His insights and personalized coaching have made a tremendous impact.',
    image: null,
  },

  {
    name: 'Kris Miller',
    rating: 5,
    comment: 'Matt came to our small town in Sun Valley, Idaho, and the results were fantastic!',
    image: 'krismiller.jpeg',
  },
  {
    name: 'Manny Guevara',
    rating: 5,
    comment:
      'Great experience with Quatro Pickleball and Matt’s coaching! He brings such a positive energy to the court.',
    image: null,
  },
  {
    name: 'Cheryl Morse',
    rating: 5,
    comment:
      'After taking several of Quattro Pickleball clinics my skill level has improved tremendously. The systematic approach to court positioning and strategy makes me much more adept at anticipating where I should be on the court at all times. I would highly recommend Matt’s professional guidance to those wanting to improve their game to new heights',
    image: 'cheryl.jpeg',
  },
  {
    name: 'Luis Miguel Grego Gonzalez',
    rating: 5,
    comment: 'This upped my game tremendously! You can tell Matt is passionate about teaching.',
    image: null,
  },
  {
    name: 'Connor',
    rating: 5,
    comment:
      "My experience with Quatro Pickleball and Matt was outstanding! I've improved immensely.",
    image: null,
  },
  {
    name: 'Pam Kentner',
    rating: 5,
    comment:
      "So- in-a-word- Quatro Pickleball is amazing! Matt's approach to the game is unmatched.",
    image: null,
  },
  {
    name: 'Mackenzie Burdick',
    rating: 5,
    comment:
      "I can't say enough good things about Quatro pickleball! It has transformed my approach to the game.",
    image: 'mackenzie.jpeg',
  },
  {
    name: "Coach Trish O'Neill",
    rating: 5,
    comment:
      'I was introduced to Quatro Pickleball through a friend, and it exceeded my expectations!',
    image: null,
  },
  {
    name: 'Mikkel Estrada',
    rating: 5,
    comment:
      "Quatro Pickleball rocks! Coach Matt's style is just awesome and it's all about that Quatro system which totally clicked for me. I have leveled up my skills big time and it's become my new obsession.",
    image: 'mikkel.jpeg',
  },
  {
    name: 'Laura Russell',
    rating: 5,
    comment:
      'Matt is an amazing teacher and coach. He has refined my skills and built my confidence on the court.',
    image: null,
  },
  {
    name: 'Daniel B.',
    rating: 5,
    comment:
      "We loved our Quattro Workshop. My wife and I are excited to practice what we've learned.",
    image: null,
  },
];
