interface GetawayInfo {
  title: string;
  url: string;
  imageUrl: string;
  description: string;
  startDate?: string;
  endDate?: string;
  contactEmail?: string;
}

export const GETAWAYS: GetawayInfo[] = [
  {
    title: 'Courmayeur Italian Alps',
    url: 'https://www.pickleballitalytrips.com/courmayeur-panegasser-feb-2025',
    imageUrl:
      'https://files.bounceassets.com/images/users/c906fd0a-caee-4376-9e1a-20106c669723.png',
    description: 'Join us for an unforgettable pickleball experience in the Italian Alps',
    startDate: '2025-02-24',
    endDate: '2025-03-02',
  },
  {
    title: 'Piemonte, Italy',
    url: 'https://www.pickleballitalytrips.com/piemonte-matt-panegasser-june-2025',
    imageUrl:
      'https://files.bounceassets.com/images/users/af93be95-4da3-4c67-bef4-059bf7f516a5.png',
    description: 'Experience pickleball in the heart of Italian wine country',
    startDate: '2025-06-16',
    endDate: '2025-06-23',
  },
  {
    title: 'Whidbey Island, Washington',
    url: 'https://www.pickleballitalytrips.com/whidbey-island',
    imageUrl:
      'https://files.bounceassets.com/images/users/143ef18f-3414-4f5c-b05a-407451530998.png',
    description: 'A Pacific Northwest pickleball retreat with stunning coastal views',
    contactEmail: 'quatro@bounce.game',
  },
  {
    title: 'Florence, Italy',
    url: 'https://www.pickleballitalytrips.com/florence',
    imageUrl:
      'https://files.bounceassets.com/images/users/1d1b919c-74b1-48f8-8e50-d82959a5b8c5.png',
    description: 'Play pickleball in the birthplace of the Renaissance',
    contactEmail: 'quatro@bounce.game',
  },
];

export const DEFAULT_DISTANCE = 50;
export const MAX_DISTANCE = 10000000;
export const QUATRO_GETAWAY_LESSONS_LIMIT = 10;
