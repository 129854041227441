import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import {
  useGetQuatroCoachesQuery,
  useGetQuatroUpcomingClinicLessonsQuery,
  useGetQuatroUpcomingRetreatLessonsQuery,
} from 'types/generated/client';
import QuatroLayout, { OG_IMAGE } from 'components/Quatro/Layout';
import { BecomeSection, NewsletterSection } from 'components/Quatro/Sections';
import Head from 'components/utilities/Head';
import {
  CoachesSection,
  DesignedSection,
  FeedbackSection,
  FounderSection,
  HeroSection,
  LearnMoreSection,
  PartnersSection,
  UpcomingEventsSection,
} from './Sections';
import { QUATRO_LEADER_IDS } from './constants';

const TITLE = 'Quatro Pickleball: Improve Your Game Through The Power of 4';
const DESCRIPTION =
  'Elevate your pickleball game with Quatro clinics and getaways. Experience the Power of 4 methodology with world-class instruction.';

const QuatroHome = () => {
  const { data: quatroUpcomingClinicLessonsData } = useGetQuatroUpcomingClinicLessonsQuery({
    variables: {
      ids: QUATRO_LEADER_IDS,
      limit: 50,
      offset: 0,
    },
  });

  const { data: quatroCoachesData } = useGetQuatroCoachesQuery({
    variables: {
      ids: QUATRO_LEADER_IDS,
      limit: 50,
      offset: 0,
    },
  });

  const { data: retreatLessonsData } = useGetQuatroUpcomingRetreatLessonsQuery({
    variables: {
      ids: QUATRO_LEADER_IDS,
      limit: 50,
      offset: 0,
    },
  });

  return (
    <>
      <QuatroLayout>
        <Head title={TITLE} description={DESCRIPTION} ogImage={OG_IMAGE} preventBrandTitle />
        <HeroSection />
        <LearnMoreSection />
        {/* <UpcomingEventsSection
          retreatLessonsData={retreatLessonsData}
          quatroUpcomingClinicLessonsData={quatroUpcomingClinicLessonsData}
        />
        <CoachesSection quatroCoachesData={quatroCoachesData} /> */}
        <BecomeSection />
        <FounderSection />
        <DesignedSection />
        <FeedbackSection />
        <PartnersSection />
        <NewsletterSection />
      </QuatroLayout>
    </>
  );
};

export default QuatroHome;
