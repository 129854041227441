import ReviewCard from './ReviewCard';
import { reviews } from './data';

const FeedbackSection = () => {
  return (
    <section className="mt-28 ">
      <div className="quatro-container mx-auto">
        <h2 className="typography-informative-heading-desktop">What players are saying</h2>
        <div className="mt-10 grid grid-cols-3  gap-4  max-md:grid-cols-2 max-sm:grid-cols-1 ">
          {reviews.map((review, key) => (
            <ReviewCard {...review} key={key} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
