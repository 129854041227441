import { memo } from 'react';
import { QUATRO_COACHES } from 'constants/pages';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import { getProfileImageUrlOrPlaceholder } from 'utils/shared/user/getProfileImageUrlOrPlaceholder';
import { CoachesSectionProps } from 'screens/QuatroHome/props';
import { ButtonLink } from 'components/Quatro/Button';
import CardCoach from 'components/cards/CardCoach';

const MemoizedCardCoach = memo(CardCoach);

const CoachesSection: React.FC<CoachesSectionProps> = ({ quatroCoachesData }) => {
  return (
    <section className="mt-28">
      <div className="container mx-auto flex flex-col items-center justify-center px-4 sm:px-0">
        <h2 className="typography-informative-heading-desktop">Quatro coaches</h2>
        <div className="mt-10 grid grid-cols-4 gap-6 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
          {quatroCoachesData?.userProfiles.map((coach) => (
            <MemoizedCardCoach
              key={coach.id}
              id={coach.id}
              slug={coach.username || coach.id}
              photoUrl={getProfileImageUrlOrPlaceholder({
                path: coach.profileImagePath || '',
                placeholder: EMPTY_COACH_AVATAR_SRC,
              })}
              certificationImages={[]}
              fullName={coach.fullName || ''}
              price={coach.priceUnitAmountRemoteCoachDefault || 0}
              distance={0}
              followersCount={coach.followersAggregate?.aggregate?.count || 0}
              defaultSport={coach.defaultSport || ''}
            />
          ))}
        </div>
        <ButtonLink size="lg" variant="secondary" href={QUATRO_COACHES} className="mt-12">
          View all coaches
        </ButtonLink>
      </div>
    </section>
  );
};

export default CoachesSection;
