import { QUATRO_FOUNDER } from 'constants/pages';
import { ButtonLink } from 'components/Quatro/Button';

const FounderSection = () => {
  return (
    <section className="mt-28">
      <div className="quatro-container mx-auto  flex justify-between gap-20 max-md:flex-col max-md:gap-10">
        <img
          src={'/images/quatro/home/founder.png'}
          alt="founder-image"
          className="max-md:h-[28rem]"
        />
        <div className="flex flex-col gap-6">
          <span className="typography-product-heading-compact-desktop text-quatro-blue-text">
            Q4PB Founder & Director of Instruction
          </span>
          <h5 className="typography-informative-subheading-highlight">
            "I believe in breaking things down in the easiest way possible through a process I
            call Quatro Pickleball."
          </h5>
          <p className="typography-informative-subheading-compact text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            Meet Matt PaneGasser, the visionary behind Quatro Pickleball. With a profound
            understanding of the nuances of the game and a desire to make pickleball accessible to
            all, Matt has crafted a unique philosophy that redefines how we approach learning and
            mastering the fastest growing sport in America.
          </p>
          <ButtonLink className="font-normal" href={QUATRO_FOUNDER} size="lg">
            Learn More
          </ButtonLink>
        </div>
      </div>
    </section>
  );
};

export default FounderSection;
