import { HeroGradientStyled } from 'screens/QuatroHome/styles';
import HeroCard from './HeroCard';

const HeroSection = () => {
  return (
    <section className="relative flex min-h-[calc(120dvh_-_theme(height.quatro-nav))] flex-col">
      <video
        src="/videos/quatro/quatro-home.mp4"
        className="absolute inset-0 z-[1] h-full w-full object-cover mix-blend-multiply"
        controls={false}
        autoPlay
        muted
        loop
      />
      <HeroGradientStyled />
      <div className="container relative z-[3] mx-auto flex h-full flex-1 flex-col items-center justify-center px-4 py-16 sm:px-0">
        <div className="mt-[2rem] flex flex-col gap-2  text-center font-trajan text-color-text-lightmode-invert">
          <h2 className="text-[2.5rem] max-lg:text-[1.5rem] max-sm:text-[1.13rem]">
            THE POWER OF 4
          </h2>
          <h1 className="text-[5rem] max-lg:text-[3rem] max-sm:text-[2.5rem]">QUATRO PICKLEBALL</h1>
        </div>

        {/* <div className="ml-auto mt-auto flex gap-20 pt-5 max-lg:flex-wrap">
          <HeroCard
            imageSrc="/images/quatro/home/hero-image-1.png"
            title={'Quatro Coaches'}
            btnHref={'/quatro/coaches'}
          />
          <HeroCard
            title={'Upcoming Events'}
            btnHref={'#upcoming-events'}
            imageSrc="/images/quatro/home/hero-image-2.png"
          />
        </div> */}
      </div>
    </section>
  );
};

export default HeroSection;
